import React from 'react'

import {
  Spacing,
  getResponsiveSpacingBottomClasses,
  getResponsiveSpacingTopClasses,
} from '@/helpers/spacing'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import {
  Body2,
  COLORS,
  CloudinaryImage,
  Layout,
  Spacer,
} from 'ethos-design-system'
import { MODULES } from 'lib/@getethos/analytics/constants'
import { cn } from 'lib/client-utils'

import { useFeFakePreInterview } from '@/hooks/features/useFeFakePreInterview'
import {
  TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW,
  WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW,
  useWillsFunnelFlow,
} from '@/hooks/features/useWillsFunnelFlow'
import { useWillsLPV2_2Experiment } from '@/hooks/features/useWillsLPExperimentV2_2'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import Markdown from '../global/Markdown'
import styles from './ValueProps.module.scss'

interface ValuePropsContentProps {
  heading: string
  mobileHeading: string
  columns: Column[]
  ctaLabel: string
  clickthroughUrl: string
  backgroundImage: string
  minimalTheme?: boolean
  willsTheme?: boolean
  ctaSubtext?: string
  queryParams?: string
  spacingTop?: Spacing
  spacingBottom?: Spacing
}

interface Column {
  groupImage: string
  imageAlt: string
  groupTitle?: string
  groupBodyCopy?: string
  nslpBodyCopy?: string
}

const iconDimensions = [132, 132, 132, 132]

const WillsValueProps = ({ moduleData }: ValuePropsProps) => {
  const { heading, columns, ctaLabel } = moduleData
  const { handleNavigateToApp } = useNavigateToApp()
  const { flow } = useWillsFunnelFlow()

  const { isOnTreatment: isOnWillsV2_2Treatment } = useWillsLPV2_2Experiment()

  const handleCtaClicked = (customFlow?: string): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel,
        module: MODULES.VALUE_PROPS,
        flow: customFlow || flow,
      },
    })

    handleNavigateToApp(
      {},
      false,
      false,
      false,
      moduleData?.clickthroughUrl,
      customFlow || flow
    )
  }

  const WillsV2_2Buttons = () => {
    return (
      <div className={styles.willsCtaV2_2}>
        <Button
          buttonTitle={'Create your trust'}
          variant="primary"
          size="md"
          onClick={() => handleCtaClicked(TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW)}
        />
        <Button
          buttonTitle={'Create your will'}
          variant="primary"
          size="md"
          onClick={() => handleCtaClicked(WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW)}
        />
      </div>
    )
  }

  const spacingBottom = getResponsiveSpacingBottomClasses(
    moduleData.spacingBottom
  )
  const spacingTop = getResponsiveSpacingTopClasses(moduleData.spacingTop)
  return (
    <div
      className={classNames(
        styles.valuePropsWillsContainer,
        spacingBottom,
        spacingTop
      )}
    >
      <div className={styles.contentContainer}>
        <div className={styles.willsHeading}>
          <Markdown input={heading} />
        </div>
        <div className={styles.columnContainer}>
          {columns.map((column, index) => (
            <div
              className="flex min-w-[272px] flex-col items-center gap-[20px]"
              key={`ValueProps-column_${index}`}
            >
              <div className="flex h-[132px] w-[132px] rounded-full">
                <div className="w-[132px]">
                  <CloudinaryImage
                    publicId={column.groupImage}
                    width={iconDimensions}
                    height={iconDimensions}
                    alt={column.imageAlt}
                  />
                </div>
              </div>
              {column.nslpBodyCopy && (
                <div className={styles.nslpBodyCopy}>
                  <Markdown input={column.nslpBodyCopy} />
                </div>
              )}
            </div>
          ))}
        </div>
        {isOnWillsV2_2Treatment ? (
          <WillsV2_2Buttons />
        ) : (
          <div className={styles.willsCta}>
            <Button
              buttonTitle={ctaLabel}
              variant="primary"
              size="lg"
              onClick={() => handleCtaClicked()}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const ValuePropsContent = ({
  heading,
  mobileHeading,
  columns,
  ctaLabel,
  clickthroughUrl,
  minimalTheme = false,
  ctaSubtext,
  queryParams,
  willsTheme = false,
}: ValuePropsContentProps) => {
  const { getClickthroughUrl: getFeFakePreInterviewClickthroughUrl } =
    useFeFakePreInterview()

  const finalClickthrough = queryParams
    ? `${clickthroughUrl}?${queryParams}`
    : clickthroughUrl

  const finalClickthroughWithExperiment =
    getFeFakePreInterviewClickthroughUrl(finalClickthrough)

  const handleCtaClicked = (): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel,
        clickthroughUrl: finalClickthroughWithExperiment,
        module: 'ValueProps',
      },
    })
  }

  return (
    <div
      className={cn('flex flex-col text-center', {
        [styles.minimalTheme]: minimalTheme,
        'pt-8 md:pt-24': !minimalTheme,
      })}
    >
      {!minimalTheme && <Spacer.H4 />}
      <div className={styles.heading}>
        <div className="block md:hidden">
          <Markdown input={mobileHeading} />
        </div>
        <div className="hidden md:block">
          <Markdown input={heading} />
        </div>
      </div>
      <div
        className={cn(
          'mb-0 flex flex-col items-center md:mb-14 md:flex-row md:justify-center',
          {
            'mb-12 gap-y-8 md:mb-16 md:gap-x-20': minimalTheme,
          }
        )}
      >
        {columns.map((column, index) => (
          <div
            className={cn('', {
              'w-72 pb-8 md:pb-0': !minimalTheme,
              'flex-1': minimalTheme,
            })}
            key={`ValueProps-column_${index}`}
          >
            <div
              className={cn('mx-auto mb-4 h-24 w-24 md:h-28 md:w-28', {
                'mb-6 flex items-center justify-center rounded-full bg-[#F3FAF6]':
                  minimalTheme,
              })}
            >
              <div className={cn({ 'w-12 md:w-16': minimalTheme })}>
                <CloudinaryImage
                  publicId={column.groupImage}
                  width={iconDimensions}
                  height={iconDimensions}
                  alt={column.imageAlt}
                />
              </div>
            </div>
            {column.groupTitle && (
              <div className={styles.columnTitle}>
                <Markdown input={column.groupTitle} />
              </div>
            )}
            <div className={styles.columnBodyCopy}>{column.groupBodyCopy}</div>
            {column.nslpBodyCopy && (
              <div className={styles.nslpBodyCopy}>
                <Markdown input={column.nslpBodyCopy} />
              </div>
            )}
          </div>
        ))}
      </div>
      {!minimalTheme && <div className={styles.mobileCTASpace} />}
      <div
        className={cn({
          'mb-12 md:mb-24 md:mt-1': !minimalTheme,
        })}
      >
        <a
          href={finalClickthroughWithExperiment}
          onClick={handleCtaClicked}
          className={styles.cta}
        >
          {minimalTheme ? (
            <div className="div">{ctaLabel}</div>
          ) : (
            <Body2.Regular400 color={COLORS.WHITE}>{ctaLabel}</Body2.Regular400>
          )}
        </a>
      </div>
      {!minimalTheme && <Spacer.H4 />}
      {minimalTheme && ctaSubtext && (
        <div className={cn(styles.ctaSubtext, 'mt-6')}>
          <Markdown input={ctaSubtext} />
        </div>
      )}
    </div>
  )
}
interface ValuePropsProps {
  moduleData: ValuePropsContentProps
}

const ValueProps = ({ moduleData }: ValuePropsProps) => {
  if (moduleData?.willsTheme) {
    return <WillsValueProps moduleData={moduleData} />
  }

  return (
    <div className="relative">
      {!moduleData.minimalTheme && (
        <div className={styles.whiteCurveOverlay}>
          <div className={styles.inset} />
        </div>
      )}
      {!moduleData.minimalTheme && (
        <div className="absolute bottom-0 left-0 hidden md:block">
          <CloudinaryImage
            publicId={moduleData.backgroundImage}
            width={[1, 1, 1440, 1440]}
            height={[1, 1, 700, 700]}
            alt={'Blue swoosh background image'}
          />
        </div>
      )}
      <div
        className={cn('relative', {
          'bg-gradient-to-br from-skyBlue to-transparent':
            !moduleData.minimalTheme,
        })}
      >
        <Layout.HorizontallyPaddedContainer>
          <ValuePropsContent {...moduleData} />
        </Layout.HorizontallyPaddedContainer>
      </div>
    </div>
  )
}

export default ValueProps
